import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { base_url } from '../constants/Constants';
import Barcode from 'react-barcode';
import {barcode} from '../models/Barcode';
import {Setting} from '../models/Setting';
import '../styles/Styles.css';

class  TestBarcode extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            startid:'',
            endid:'',
            list:{},
            validations:{},
            settings : Setting,
            formObj:barcode,
            classname:'labels',
            labelname:'p-2 colmd-3',
            temp:[],
            showlabel:true,
            from: ''
        }
    }

    componentDidMount(){
        let tempObj = {}
        for(let key in barcode){ 
            tempObj[key] = "" 
        }
        this.setState({validations:tempObj});
        let tempObjs = {}
        for(let key in barcode){ 
            tempObjs[key] = ""
        }
        this.setState({formObj:tempObjs})
        let url = this.props.location.search
        let params = queryString.parse(url)
        this.setState({startid: params.id1})
        this.setState({endid: params.id2})
        this.setState({from: params.from})
        this.getSettings()
    }

    getSettings = () => {
        axios.get(base_url+'settings/list', this.props.user.header )
        .then(res => {
            if ( res.data.length > 0 ){
                for(var i=0;i<res.data.length;i++){
                    this.setState({ settings:res.data[i]});	
                }	
            }
        })
        .catch(function (error) { console.log(error); });
    }



    validate = (val) => {
        let tempObj = this.state.validations;
        switch(val){
            case "selectlabel": 
                tempObj.selectlabel = Validators.select(this.state.formObj.selectlabel); 
                break;
        }
        this.setState({validations:tempObj});
    }

    format = (val, e) => { 
        let tempObj = this.state.formObj
        switch(val){
            case "selectlabel": 
                tempObj.selectlabel = Formators.name(e.target.value);
                if(tempObj.selectlabel !== "" && tempObj.selectlabel !== undefined){
                    this.setState({classname:"printlabel",labelname1:"p-2 col-md-3 labelname",showlabel:false})
                }
                break
        }
        this.setState({formObj:tempObj});
    }

    renderLabelTypes = () => {
        if(this.state.settings.labelTypes != undefined && this.state.settings.labelTypes.length > 0){
            return this.state.settings.labelTypes.map((item, index) =>{
                return (
                    <option value={item.label} key={index} id={index}>{item.label}</option>
                )
            }, this)
        }
    }
    
    addObj = () =>{
        this.setState({classname:"printlabel",labelname1:"p-2 col-md-3 labelname",showlabel:false});
    }

    renderList = () => {
        if(this.state.endid==="books"){
            return(
                <div>
                    {this.state.showlabel ? 
                        <div className={this.state.labelname}>
                            <label htmlFor="idselectlabel" className="label-custom" >Select Label</label> 
                            <select 
                                value={this.state.formObj.selectlabel} 
                                id="idselectlabel"  
                                onChange={(e) => this.format("selectlabel",e)}  
                                onBlur={() => this.validate("selectlabel")}  
                                style={this.state.validations.selectlabel==="" ? {} : {"borderColor": "red"}}
                                className="form-control form-control-sm"
                            >
                                <option value=""></option>
                                {this.renderLabelTypes()}
                            </select>
                            <span className="errormsg">{this.state.validations.selectlabel}</span>
                        </div>
                        :null
                    }
                </div>
            )
        }
    }

    renderlist = () => {
        var temp = this.state.startid
        var text = temp.split(",")
        if(text!==''){
            return(
                <div className={this.state.from==='printSection' ? 'grid-3-col' :`barcode-container`}>
                    {text.map((item, index) => {
                        if(item!==""){
                            if(this.state.endid==="books"){
                                return(
                                    <div className="singlelabel bcode">
                                        <div className={this.state.classname}>
                                            <p className="labeltext">{this.state.formObj.selectlabel}</p>
                                            <Barcode value={item} height={30} ref={ref => (this.ref = ref)}/>
                                        </div>
                                    </div>
                                )
                            }else{
                                return(
                                    <div className="singlelabel">
                                        <p className="labeltext">{this.state.endid}</p>
                                        <Barcode value={item} height={30} ref={ref => (this.ref = ref)}/>
                                    </div>
                                )
                    }   }   }, this)}
                </div>
            )
        }
    }

    render(){ 
        return(
            <div className="container-fluid m-0 p-0">
                <div className="d-flex flex-column">
                    {this.renderList()}
                    {this.renderlist()}
                </div>
            </div>
        )
    }
}   

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(TestBarcode);