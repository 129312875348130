import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import {barcode} from '../models/Barcode';
import { base_url } from '../constants/Constants';
import '../styles/Styles.css';
import {Setting} from '../models/Setting';


class PrintBarcodes extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			validations : {},
			formObj:barcode,
			BookData:[],
			CdData:[],
			JournalData:[],
			settings : Setting,
			multiplebarcode:false,
			showmodel:true,
			rangebarcode:false,
		}

	}

	componentDidMount(){
		let tempObj = {};
		for (let key in barcode) { tempObj[key] = ""; }
		this.setState({validations:tempObj});
		let tempObjs = {};
		for (let key in barcode) { tempObjs[key] = ""; }
		this.setState({formObj:tempObjs});
		this.getBookData();
		this.getCdData();
		this.getJournalData();
		this.getSettings();
		
	}

	NextButton=(id1,id2,id3)=>{
		if(id2=="Multiple Printed Barcode"){
			this.setState({multiplebarcode:true, showmodel:false,rangebarcode:false});
		}
		else if(id2=="Ranges Printed Barcode"){
		this.setState({rangebarcode:true,showmodel:false,multiplebarcode:false})

		}
	}
	getBookData = () => {
		axios.get(base_url+'books_catalog/list',  this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({ BookData:res.data});
			}
		}).catch(function (error) { console.log(error); });
	}

	getCdData = () => {
		axios.get(base_url+'cd_catalog/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({CdData:res.data});
			}
		}).catch(function (error) { console.log(error); });
	}

	getJournalData= () => {
		axios.get(base_url+'journals_catalog/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({JournalData:res.data});
				
			}
		}).catch(function (error) { console.log(error); });
	}

	addObj = () => { 
		var temp=this.state.formObj;
		temp.barcodeto='';
		temp.barcodefrom='';
		temp.notes='';
		this.setState({formObj:temp});
	}

	getSettings = () => {
		axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				for(var i=0;i<res.data.length;i++){
					this.setState({ settings:res.data[i]});	
				}	
			}
		}).catch(function (error) { console.log(error); });
	}

	Goback=()=>{
		var temp=this.state.formObj;
		temp.barcodeto='';
		temp.barcodefrom='';
		temp.notes='';
		this.setState({formObj:temp});
		this.setState({showmodel:true,rangebarcode:false});
	}

	BackTohome=()=>{
		var temp=this.state.formObj;
		temp.barcodeto='';
		temp.barcodefrom='';
		temp.notes='';
		this.setState({formObj:temp});
		this.setState({showmodel:true,multiplebarcode:false});
	}

	printbarcode2 = (id1, id2) => {
		console.log(id1, id2)
		this.setState({rangebarcode: true})
		if(id1==="Books"){
			let temp = "";
			for(var i=0; i<this.state.BookData.length; i++){
				if(this.state.BookData[i].accnumber >= this.state.formObj.barcodefrom && this.state.BookData[i].accnumber <= this.state.formObj.barcodeto){
					temp += this.state.BookData[i].accnumber + " ";
				}
			}
			var text = temp.split(" ");
			window.open('/barcode?id1=' + text + '&id2=' + id2 + '&from=' + 'printSection' + '', "_blank", "top=500,left=500,width=500,height=500")
		}
		else if(id1==="CD/DVD"){
			let temp = "";
			for(var i=0; i<this.state.CdData.length; i++){
				if(this.state.CdData[i].cdnumber >= this.state.formObj.barcodefrom && this.state.CdData[i].cdnumber <= this.state.formObj.barcodeto){
					temp += this.state.CdData[i].cdnumber + " ";
				}
			}
			var text = temp.split(" ");
			window.open('/barcode?id1=' + text + '&id2=' + id2 + '&from=' + 'printSection' + '', "_blank", "top=500,left=500,width=500,height=500");
		}
		else if(id1==="Journals"){
			let temp = "";
			for(var i=0; i<this.state.JournalData.length; i++){
				if(this.state.JournalData[i].barcode >= this.state.formObj.barcodefrom && this.state.JournalData[i].barcode && this.state.formObj.barcodeto){
					temp += this.state.JournalData[i].barcode + " ";
				}
			}
			var text = temp.split(" ");
			window.open('/barcode?id1=' + text + '&id2=' + id2 + '&from=' + 'printSection' + '', "_blank", "top=500,left=500,width=500,height=500");
		}

	}

	printbarcode1 = (id1, id2) => {
		this.setState({multiplebarcode: true});
		if(id1==="Books"){
			var temp = this.state.formObj.notes.split(" ")
			var text = '';
			for(var i=0; i<temp.length; i++){
				for(var j=0; j<this.state.BookData.length; j++){
					if(temp[i] == this.state.BookData[j].accnumber){
						text += temp[i] + " ";
					}
				} 
			}
			var test = text.split(" ");
			window.open('/barcode?id1=' + test + '&id2=' + id2 + '&from=' + 'printSection' + '', "_blank", "top=500,left=500,width=500,height=500");
			//this.addObj();
		}
		else if(id1==="CD/DVD"){
			var temp = this.state.formObj.notes.split(" ")
			var text = '';
			for(var i=0; i<temp.length; i++){
				for(var j=0; j<this.state.CdData.length; j++){
					if(temp[i] === this.state.CdData[j].cdnumber){
						text += temp[i] + " ";
					}
				}
			}
			var test = text.split(" ");
			window.open('/barcode?id1=' + test + '&id2=' + id2 + '&from=' + 'printSection' + '', "_blank", "top=500,left=500,width=500,height=500")
			//this.addObj();
		}
		else if(id1==="Journals"){
			var temp = this.state.formObj.notes.split(" ")
			var text = '';
			for(var i=0; i<temp.length; i++){
				for(var j=0; j<this.state.JournalData.length; j++){
					if(temp[i] === this.state.JournalData[j].barcode){
						text += temp[i] + " ";
					}
				}
			}
			var test = text.split(" ");
			window.open('/barcode?id1=' + test + '&id2=' + id2 + '&from=' + 'printSection' + '', "_blank", "top=500,left=500,width=500,height=500")
			//this.addObj();
		}
	}

	validate = (val) => {
		let tempObj = this.state.validations;
		switch(val){
			case "selectcatalog": tempObj.selectcatalog = Validators.select(this.state.formObj.selectcatalog); break;
			case "selectcatalog1": tempObj.selectcatalog1 = Validators.select(this.state.formObj.selectcatalog1); break;
			case "selectlabel": tempObj.selectlabel = Validators.select(this.state.formObj.selectlabel); break;
			case "barcodetype": tempObj.barcodetype = Validators.select(this.state.formObj.barcodetype); break;
			case "notes": tempObj.notes = Validators.name(this.state.formObj.notes); break;
			case "barcodefrom": tempObj.barcodefrom = Validators.name(this.state.formObj.barcodefrom); break;
			case "barcodeto": tempObj.barcodeto = Validators.name(this.state.formObj.barcodeto); break;
		}
		this.setState({validations:tempObj});
	}

	format = (val, e) => { 
		let tempObj = this.state.formObj;
		switch(val){
			case "selectcatalog": tempObj.selectcatalog = Formators.name(e.target.value); break;
			case "selectcatalog1": tempObj.selectcatalog1 = Formators.name(e.target.value); break;
			case "selectlabel": tempObj.selectlabel = Formators.name(e.target.value); break;
			case "barcodetype": tempObj.barcodetype = Formators.name(e.target.value); break;
			case "notes": tempObj.notes = Formators.name(e.target.value); break;
			case "barcodefrom": tempObj.barcodefrom = Formators.name(e.target.value); break;
			case "barcodeto": tempObj.barcodeto = Formators.name(e.target.value); break;
		}
		this.setState({formObj:tempObj});
	}

	renderLabelTypes = () => {
		if(this.state.settings.labelTypes != undefined && this.state.settings.labelTypes.length >= 0){
			return this.state.settings.labelTypes.map((item, index) => {
				return(
					<option value={item.label} id={index} key={index} >{item.label}</option>
				)
			}, this);
		}
	}

	render(){
		return(
			<div className="shadow-sm p-3 mb-5 bg-white rounded">
				<div>
					<div className="d-flex row justify-content-center">
						<h3>Print Barcodes</h3>
					</div>
				</div>
				<br/>
				{this.state.showmodel ?
					<div className="d-flex row">
						<div className="p-2 flex-fill ">
							<label htmlFor="idselectcatalog1" className="label-custom" > Catalog<span className="star">*</span></label> 
							<select 
								value={this.state.formObj.selectcatalog1} 
								id="idselectcatalog1"  
								onChange={(e)=>{this.format("selectcatalog1",e)}}  
								onBlur={()=>{ this.validate("selectcatalog1")}}  
								style={this.state.validations.selectcatalog1==="" ? {} : {"borderColor":"red"}}
								className="form-control form-control-sm"
							>
								<option value=""></option>
								<option value="Books">Books</option>
								<option value="CD/DVD">CD/DVD</option>
								<option value="Journals">Journals</option>
							</select>
							<span className="errormsg">{this.state.validations.selectcatalog1}</span>
						</div>
						<div className="p-2 flex-fill ">
							<label htmlFor="idselectlabel" className="label-custom" > Label Type<span className="star">*</span></label> 
							<select 
								value={this.state.formObj.selectlabel} 
								id="idselectlabel"  
								onChange={(e)=>{this.format("selectlabel",e)}}  
								onBlur={()=>{ this.validate("selectlabel")}}  
								style={this.state.validations.selectlabel==="" ? {} : {"borderColor":"red"}}
								className="form-control form-control-sm"
							>
								<option value=""></option>
								{this.renderLabelTypes()}
							</select>
							<span className="errormsg">{this.state.validations.selectlabel}</span>
						</div>
						<div className="p-2 flex-fill ">
							<label htmlFor="idbarcodetype" className="label-custom" > Barcode Type<span className="star">*</span></label> 
							<select 
								value={this.state.formObj.barcodetype} 
								id="idbarcodetype"  
								onChange={(e)=>{this.format("barcodetype",e)}}  
								onBlur={()=>{ this.validate("barcodetype")}}  
								style={this.state.validations.barcodetype==="" ? {} : {"borderColor":"red"}}
								className="form-control form-control-sm"
							>
								<option value=""></option>
								<option value="Multiple Printed Barcode">Multiple Printed Barcode</option>
								<option value="Ranges Printed Barcode">Ranges Printed Barcode</option>
							</select>
							<span className="errormsg">{this.state.validations.barcodetype}</span>
						</div>
						<div className="p-2">
							<button 
								type="button" 
								style={{marginTop:35, marginLeft:40}}
								className="btn btn-success btn-sm"  
								onClick={()=>{this.NextButton(this.state.formObj.selectcatalog1,this.state.formObj.barcodetype,this.state.formObj.selectlabel)}}
							>
								Next
							</button>
						</div>
					</div>
					: null
				}
				{this.state.multiplebarcode ?
					<div>	
						<div className="d-flex row"	>
							<div className="p-2  ">
								<label htmlFor="idnotes" className="label-custom" >Print barcode by entering multiple barcodes seperated by space</label> 
								<textarea  
									value={this.state.formObj.notes}  
									rows="5"
									id="idnotes"   
									onChange={(e)=>{this.format("notes", e)}} 
									className="form-control"   
									onBlur={()=>{ this.validate("notes")}}   
									style={this.state.validations.notes===""?{}:{"borderColor":"red"}} 
								/>  
								<span className="errormsg">{this.state.validations.notes}</span>
							</div>
						</div>
						<div className="d-flex row">
							<div className="p-2">
								<button type="button" style={{marginTop:7}}className="btn btn-secondary btn-sm"  onClick={()=>{this.BackTohome()}}>
									Back
								</button>  
							</div>
							<div className="p-2">
								<button 
									type="button" 
									style={{marginTop:7}}
									className="btn btn-success btn-sm"  
									onClick={()=>{this.printbarcode1(this.state.formObj.selectcatalog1, this.state.formObj.selectlabel)}}
								>
									Print
								</button>  
							</div>
						</div>
					</div>
					: null
				}
				{this.state.rangebarcode ?
					<div>
						<div className="d-flex row"	>
							<div className="p-2 ">
								<label htmlFor="idbarcodefrom" className="label-custom" >Acc/Barcode From</label> 
								<input 
									value={this.state.formObj.barcodefrom} 
									id="idbarcodefrom" 
									onChange={(e)=>{this.format("barcodefrom",e)}} 
									onBlur={()=>{ this.validate("barcodefrom")}} 
									className="form-control form-control-sm" 
									type="number"  
									required 
									style={this.state.validations.barcodefrom===""?{}:{"borderColor":"red"}} 
								/> 
								<span className="errormsg">{this.state.validations.barcodefrom}</span>		
							</div>
								
							<div className="p-2 ">
								<label htmlFor="idbarcodeto" className="label-custom" >Acc/Barcode To</label> 
								<input 
									value={this.state.formObj.barcodeto} 
									id="idbarcodeto" 
									onChange={(e)=>{this.format("barcodeto",e)}} 
									onBlur={()=>{ this.validate("barcodeto")}} 
									className="form-control form-control-sm" 
									type="number"  
									required 
									style={this.state.validations.barcodeto===""?{}:{"borderColor":"red"}} 
								/> 
								<span className="errormsg">{this.state.validations.barcodeto}</span>		
							</div>
						</div>
						<div className="d-flex row">
							<div className="p-2">
								<button type="button" style={{marginTop:7}}className="btn btn-secondary btn-sm" onClick={()=>{this.Goback()}}>
									Back
								</button>
							</div>
							<div className="p-2">
								<button 
									type="button" 
									style={{marginTop:7}}
									className="btn btn-success btn-sm"
									onClick={()=>{this.printbarcode2(this.state.formObj.selectcatalog1, this.state.formObj.selectlabel)}}
								>
									Print
								</button>
							</div>
						</div>
					</div>
					: null
				}
			</div>
		)
	}
}
		
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(PrintBarcodes);            