import React from "react";
import DatePicker from "react-datepicker";
import { date_format, time_format, datetime_format } from '../constants/Constants';
import "react-datepicker/dist/react-datepicker.css";
import "../styles/Styles.css";

export const DateOnly = ({name, value, onChange, class_name, onBlur, dateformat, tabindex}) => {
  const handleOnBlur = () => { onBlur(name); };
  let date = null
  if(value && typeof value === 'number') date = new Date(value)
  return (
    <DatePicker
      selected={date}
      onChange={e => onChange(e)}
      onBlur={handleOnBlur}
      dateFormat={dateformat}
      className={class_name}
      tabIndex={tabindex}
     />
  )
}


export const TimeOnly = ({ name, value, onChange, class_name, onBlur }) => {
    const handleOnBlur = () => { onBlur(name); };
    return (
      <DatePicker
        selected={value ? new Date(value) : null}
        onChange={val => { onChange(name, val); }}
        onBlur={ handleOnBlur }
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        timeFormat={time_format}
        dateFormat={time_format}
        className={class_name} />
    );
  };
  export const DateTime = ({ name, value, onChange , class_name, onBlur}) => {
    return (
      <DatePicker
        selected={value ? new Date(value) : null}
        onChange={val => {       
          onChange(name, val);
        }}
        onBlur={onBlur(name)}
        showTimeSelect
        timeFormat={time_format}
        timeIntervals={15}
        timeCaption="Time"       
        dateFormat={datetime_format}
        className={class_name}  />
    );
  };

