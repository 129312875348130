////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { Journals_Catalog } from '../models/Journals_Catalog';
import {Setting} from '../models/Setting';
import {DateOnly} from '../utils/DateTime';
import '../styles/Styles.css';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';


class Journals_CatalogForm extends React.Component {
constructor(props){
	super(props);
	this.state = {
		objList : [],
		showModal : false,
		settings : Setting,
		searchmessage:'',
		alertmsg:'',
		deletebtn:'btn btn-danger btn-sm showbarcodebtn',
		classname:'btn btn-success btn-sm showbarcodebtn',
		journalform:false,
		searchform:true,
		show:false,
		index:'',
		dateformat:'',
		showMessage:false,
		showSuccessMsg:false,
		disvalue:true,
		validations : {},
		formObj : Journals_Catalog,
		journalacc:'',
		year: []
	}
	this.getObjList = this.getObjList.bind(this);
	this.saveObj = this.saveObj.bind(this);
	this.addObj = this.addObj.bind(this);
	this.editObj = this.editObj.bind(this);
}

componentDidMount(){
	let tempObj = {};
	for (let key in Journals_Catalog) { tempObj[key] = ""; }
	this.setState({validations:tempObj});
	let tempObjs = {};
	for (let key in Journals_Catalog) { tempObjs[key] = ""; }
	this.setState({formObj:tempObjs});
	this.getObjList();
	this.getSettings();
	const year = new Date().getFullYear()
	let year_opt = []
	for(let i=2008; i<=year; i++){
		year_opt.unshift(i)
	}
	this.state.year = year_opt
}

getObjList = () => {
	axios.get(base_url+'journals_catalog/list', this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			this.setState({ objList:res.data});
		}
	}).catch(function (error) { console.log(error); });
}
getSettings = () => {
	axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			for(var i=0;i<res.data.length;i++){
				this.setState({ settings:res.data[i]});	
			}	
		}
		if(this.state.settings.dateformats == ''){
			this.setState({dateformat:"dd/MM/yyyy"});
		}else if(this.state.settings.dateformats !==''){
			this.setState({dateformat:this.state.settings.dateformats});
		}
		
		
	}).catch(function (error) { console.log(error); });
}

printbarcode=()=>{
	var id2="books"
	var temp = this.state.formObj.barcode  +" " +this.state.formObj.barcode;
	var text= temp.split(" ");
	window.open('/barcode?id1='+text+'&id2='+id2+'', "_blank", "top=500,left=500,width=500,height=500");		
	

}
saveObj = () => {
	if(this.state.formObj._id === ""){
		let tempo = '';
		for (let key in Journals_Catalog) { this.validate(key);}
		for (let key in this.state.validations) {tempo += this.state.validations[key];}
		if(tempo === ""){
			var text=this.state.formObj;
			text.status="Active";
			this.setState({formObj:text});
				axios.post(base_url+'journals_catalog/save', this.state.formObj, this.props.user.header ).then(res => {
				this.setState({ objList:res.data,formObj:res.data.data,journalacc:res.data.data.barcode});	
				this.setState({classname:'btn btn-success btn-sm showbarcodebtn',disvalue:false,alertmsg:'Your data is saved successfully'});
				this.setState({showSuccessMsg: true}, ()=>{
					setTimeout(() => {
					this.setState({showSuccessMsg: false })
					}, 3000);
				    });
					this.getObjList();
					
			}).catch(function (error) { console.log(error); });
		}
		}else{
		let temp='';
		for (let key in  Journals_Catalog) { this.validate(key);}
		let tempkey = this.state.validations;
		tempkey.barcode="";
	
		this.setState({validations:tempkey});
		for (let key in this.state.validations) {temp += this.state.validations[key];}
		if(temp == ''){
		axios.post(base_url+'journals_catalog/save', this.state.formObj, this.props.user.header ).then(res => {
			this.setState({formObj:res.data.data,journalacc:res.data.data.barcode,classname:'btn btn-success btn-sm showbarcodebtn',disvalue:false,alertmsg:'Your data is updated successfully'});
			this.setState({showSuccessMsg: true}, ()=>{
				setTimeout(() => {
				this.setState({showSuccessMsg: false })
				}, 3000);
			});
			this.getObjList();
		}).catch(function (error) { console.log(error); });
		}
	}
}

setNewform = () =>{
	this.addObj(); 
	this.setState({journalform:true,searchform:false,searchtable:false,classname:'hidebarcodebtn'});
}

searchJournal =(id,by) => {
	if(id != "" && id != undefined){
		let temp={search : id ,type: by}
		axios.post(base_url+'journals_catalog/search1', temp, this.props.user.header )
			.then(res =>{
			if(res.data.length > 0){
				let tempObj = this.state.searchmessage;
				tempObj="";
				this.setState({searchmessage:tempObj});
				this.setState({objList:res.data,searchtable:true,showSuccessMsg:false});
			}else{
			let tempObj = this.state.searchmessage;
			tempObj="Journal details you entered is not match with our records";
			this.setState({searchmessage:tempObj,showSuccessMsg:false});
			let temarr = [];
			this.setState({objList:temarr,searchtable:false});
			}
		}).catch(function (error) { console.log(error); }); 
		this.renderObjlist();
	}else{
		let tempObj = this.state.searchmessage;
		tempObj="Please Enter pissn or Journal title  to search";
		let temarr = [];
		this.setState({searchmessage:tempObj});
		this.setState({objList:temarr,searchtable:false});
		
	}

}
addObj = () => { 
	let tempObj = {};
	for (let key in  Journals_Catalog ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj});
	let tempObjs = {};
	for (let key in Journals_Catalog ) { tempObjs[key] = ""; }
	this.setState({validations:tempObjs});
	this.setState({disvalue:true,classname:'hidebarcodebtn',deletebtn:'hidebarcodebtn',showSuccessMsg:false,journalacc:''});	
}
goToSearch = () =>{
	let tempObjs = {};
	for (let key in Journals_Catalog) { tempObjs[key] = ""; }
	this.setState({validations:tempObjs});
	let tempObj = {};
	for (let key in Journals_Catalog ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj,searchmessage:""});
	this.setState({journalform:false,searchform:true,searchtable:false});
}

searchObj = () => { 
	let tempObj = {};
	for (let key in Journals_Catalog ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj, showModal:true});
}

editObj = (i) => { 
	let tempObj = {};
	for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
	this.setState({ formObj:tempObj, showModal:true});
}

deleteObj = () => { 
	let tempObj = this.state.formObj;
	if(tempObj.journalstatus=="Available"){
	this.setState({show:true});
	this.setState({index:tempObj});
}
else{
	this.setState({showMessage:true});
}
}

conformDelete=()=>{
	var tempObj=this.state.index;
		tempObj.status = 'Inactive';
		axios.post(base_url+'journals_catalog/save', tempObj , this.props.user.header ).then(res =>{
			this.setState({show:false});
			this.setState({alertmsg:'Your data is deleted successfully ',classname:'hidebarcodebtn',deletebtn:'hidebarcodebtn'});
			this.setState({showSuccessMsg: true}, ()=>{
				setTimeout(() => {
				this.setState({showSuccessMsg: false })
				}, 3000);
			});
			let tempObj = {};
			for (let key in  Journals_Catalog ) { tempObj[key] = ""; }
			this.setState({ formObj:tempObj,disvalue:true});

	}).catch(function (error) { console.log(error); });

}

getDetails = (i,id) => { 
	let tempObjs = {};
	for (let key in Journals_Catalog  ) { tempObjs[key] = ""; }
	this.setState({validations:tempObjs});
	let tempObj = this.state.objList[i];
	// console.log(tempObj)
	this.setState({formObj:tempObj,journalacc:tempObj.barcode});
	this.setState({showModal:false});
	this.setState({journalform:true,searchform:false,searchtable:false,disvalue:false,classname:'btn btn-success btn-sm showbarcodebtn',deletebtn:'btn btn-danger btn-sm showbarcodebtn'});
}

searchType=(id,title,name)=>{
	if(id !== '' && id !== undefined){
		this.searchJournal(id,"pissn");
	}else if(title !== '' && title !== undefined){
		var output = title.replace(/\w+/g, function(txt) {
			// uppercase first letter and add rest unchanged
			return txt.charAt(0).toUpperCase() + txt.substr(1);
		  }).replace(/\s/g, '');
		this.searchJournal(output, "Name");
	}
	else if(name !== '' && name !== undefined){
		var output = name.trim();
		var input=output.substring(0, 1).toUpperCase() + output.substring(1);
		this.searchJournal(input, "author");
		}
	
	else{
		let tempObj = this.state.searchmessage;
		tempObj="Please Enter pissn or  Journal title to search";
		let temarr = [];
		this.setState({searchmessage:tempObj});
		this.setState({objList:temarr,searchtable:false});
	}
}

Verifybarcode = (barcode) =>{
	if(barcode !== "" ){
		if(barcode !== this.state.journalacc){
		var cardObj = {barcode:this.state.formObj.barcode};
		var tempObj=this.state.validations;
		axios.post(base_url+'journals_catalog/barcodeverify', cardObj,this.props.user.header)        
			.then(res => {
				if(res.data.msg === 'success'){   
					tempObj.barcode='The barcode you have entered is already associated with another Journal book'; 
					this.setState({validations:tempObj});
				}               
			}).catch(function (error) { console.log(error);}); 
	}
   }
		
}
validate = (val) => {
	let tempObj = this.state.validations;
	switch(val){
		case "pissn": tempObj.pissn = Validators.name(this.state.formObj.pissn); break;
		case "barcode": tempObj.barcode = Validators.name(this.state.formObj.barcode);this.Verifybarcode(this.state.formObj.barcode);break;
		case "eissn": tempObj.eissn = Validators.name(this.state.formObj.eissn); break;
		case "journaltitle": tempObj.journaltitle = Validators.name(this.state.formObj.journaltitle); break;
		case "journalpublisher": tempObj.journalpublisher = Validators.name(this.state.formObj.journalpublisher); break;
		case "journaltype": tempObj.journaltype = Validators.name(this.state.formObj.journaltype); break;
		case "price": tempObj.price = Validators.number(this.state.formObj.price); break;
		case "journalstatus": tempObj.journalstatus = Validators.select(this.state.formObj.journalstatus); break;		
	}
	this.setState({validations:tempObj});
	} 

format = (val, e) => { 
	let tempObj = this.state.formObj;
	switch(val){
		case "pissn": tempObj.pissn = Formators.name(e.target.value); break;
		case "barcode": tempObj.barcode = Formators.name(e.target.value); break;
		case "eissn": tempObj.eissn = Formators.name(e.target.value); break;
		case "journaltitle": tempObj.journaltitle = Formators.name(e.target.value); break;
		case "journalpublisher": tempObj.journalpublisher = Formators.name(e.target.value); break;
		case "journaltype": tempObj.journaltype = Formators.name(e.target.value); break;
		case "vendor": tempObj.vendor = Formators.name(e.target.value); break;
		case "ponum": tempObj.ponum = Formators.name(e.target.value); break;
		case "podate": tempObj.podate = Formators.name(e.target.value); break;
		case "price": tempObj.price = Formators.name(e.target.value); break;
		case "periodicity": tempObj.periodicity = Formators.name(e.target.value); break;
		case "volumeandyear": tempObj.volumeandyear = Formators.name(e.target.value); break;
		case "checknum": tempObj.checknum = Formators.name(e.target.value); break;
		case "checkdate": tempObj.checkdate = Formators.name(e.target.value); break;
		case "month": tempObj.month= Formators.name(e.target.value); break;
		case "year": tempObj.year = Formators.name(e.target.value); break;
		case "volumeno": tempObj.volumeno= Formators.name(e.target.value); break;
		case "journalstatus": tempObj.journalstatus = Formators.name(e.target.value); break;
		case "issueno": tempObj.issueno = Formators.name(e.target.value); break;
		case "notes": tempObj.notes = Formators.name(e.target.value); break;
	}
	this.setState({formObj:tempObj,showSuccessMsg:false});
	} 

renderObjlist = () => {
	if( this.state.objList != undefined && this.state.objList.length > 0){
		return this.state.objList.map((item, index) =>{
			return (
				<tr key={index} id={index} onClick={()=>{this.getDetails(index,item.pissn)}}>
					<td>{item.pissn}</td>
					<td>{item.journaltitle}</td>
					<td>{item.journalpublisher}</td>
					<td>{item.journalstatus}</td>
				</tr>
				)
			}, this);
	}
}

renderJournalTypes = () => {
	if( this.state.settings.journalTypes != undefined && this.state.settings.journalTypes.length > 0){
		return this.state.settings.journalTypes.map((item, index) =>{
			return (
				<option value={item.journaltype} key={index} id={index}>{item.journaltype}</option>
				)
		}, this);
	}
}

changeEvent = (e) =>{
	this.setState({ endDate : e });
	var tempObj=this.state.formObj;
		tempObj.checkdate=e ;
	this.setState({formObj:tempObj});
}
changeEvents = (e) =>{
	this.setState({ endDate : e });
	var tempObj=this.state.formObj;
		tempObj.podate=e ;
	this.setState({formObj:tempObj});
}

render() {
	return (
			<div className="shadow-sm p-3 mb-5 bg-white rounded">
				<div><div className="d-flex row justify-content-center"><h3>Journals Catalog</h3></div></div><br/>
					{this.state.showSuccessMsg ?
				<div className="d-flex row">
					<div className="p-2 flex-fill">
					<FlashMessage duration={3000} persistOnHover={true}>
					{flashMessage(this.state.alertmsg, "Success")}
					</FlashMessage>
					</div>	
				</div>
				:null
				}
				{this.state.searchform?
				<div className="d-flex row">
						<div className="p-2 flex-fill">
								<label htmlFor="idpissn" className="label-custom" >P-ISSN</label> 
								<input value={this.state.formObj.pissn} id="idpissn"  onChange={(e)=>{this.format("pissn",e)}}  className="form-control form-control-sm" onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.pissn,this.state.formObj.journaltitle): null} type="text" className='form-control form-control-sm' required /> 
								<span className="errormsg">{this.state.searchmessage}</span>
						</div>	
						<div className="p-2 flex-fill">
								<label htmlFor="idjournaltitle" className="label-custom" >Title</label> 
								<input value={this.state.formObj.journaltitle} id="idjournaltitle" onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.pissn,this.state.formObj.journaltitle): null} onChange={(e)=>{this.format("journaltitle",e)}}    className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
								
						</div>
						<div className="p-2">
							<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchType(this.state.formObj.pissn,this.state.formObj.journaltitle)}}>Search</button>
						</div>
						<div className="p-2">
							<button type="button" className="btn btn-success btn-sm" style={{marginTop:32}}  onClick={()=>{this.setNewform()}}>New</button>
						</div>
					</div>:null}
					{this.state.searchtable?
					<div className="d-flex row">
						<table className="table table-hover table-sm">
							<thead className="thead">
								<tr>
									<td>PISSN No</td>
									<td>Journal Title</td>
									<td>Journal Publisher</td>
									<td>Journal Status</td>
								</tr>
							</thead>
							<tbody>{this.renderObjlist()}</tbody>
						</table>
					</div>:null}
					{this.state.journalform?<div>

						<div className="d-flex row">
							<div className="p-2 flex-fill">
								<div className="d-flex row">
									<div className="col-md-6">
									<label htmlFor="idbarcode" className="label-custom" >Barcode<span className="star">*</span></label> 
									<input tabIndex="1"  value={this.state.formObj.barcode} id="idbarcode" onChange={(e)=>{this.format("barcode",e)}}  onBlur={()=>{ this.validate("barcode")}} className="form-control form-control-sm" style={this.state.validations.barcode===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
									<span className="errormsg">{this.state.validations.barcode}</span>
								</div>
								<div className="col-md-6">
									<label htmlFor="idpissn" className="label-custom" >P-ISSN<span className="star">*</span></label> 
									<input tabIndex="2" value={this.state.formObj.pissn} id="idpissn"  onChange={(e)=>{this.format("pissn",e)}} onBlur={()=>{ this.validate("pissn")}} className="form-control form-control-sm" style={this.state.validations.pissn===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
									<span className="errormsg">{this.state.validations.pissn}</span>
								</div>
								</div>
								<div className="d-flex row">
									<div className="col-md-6">
										<label htmlFor="ideissn" className="label-custom" >E-ISSN<span className="star">*</span></label> 
										<input tabIndex="5" value={this.state.formObj.eissn} id="ideissn"   onChange={(e)=>{this.format("eissn",e)}}  onBlur={()=>{ this.validate("eissn")}} className="form-control form-control-sm" style={this.state.validations.eissn===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
										<span className="errormsg">{this.state.validations.eissn}</span>
									</div>
									 <div className="col-md-6">
										<label htmlFor="idjournaltitle" className="label-custom" >Title<span className="star">*</span></label> 
										<input tabIndex="6" value={this.state.formObj.journaltitle} id="idjournaltitle"  onChange={(e)=>{this.format("journaltitle",e)}}   onBlur={()=>{ this.validate("journaltitle")}} className="form-control form-control-sm" style={this.state.validations.journaltitle===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
										<span className="errormsg">{this.state.validations.journaltitle}</span>
								   </div>
								</div>
								<div className="d-flex row">
									<div className="col-md-6">
										<label htmlFor="idprice" className="label-custom" >Price<span className="star">*</span></label> 
										<input tabIndex="9" value={this.state.formObj.price} id="idprice" onChange={(e)=>{this.format("price",e)}}   onBlur={()=>{ this.validate("price")}} className="form-control form-control-sm" style={this.state.validations.price===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
										<span className="errormsg">{this.state.validations.price}</span>
									</div>
									<div className="col-md-6">
										<label htmlFor="idperiodicity" className="label-custom" >Periodicity</label> 
										<input tabIndex="10" value={this.state.formObj.periodicity} id="idperiodicity"  onChange={(e)=>{this.format("periodicity",e)}}   onBlur={()=>{ this.validate("periodicity")}} className="form-control form-control-sm" style={this.state.validations.periodicity===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
										<span className="errormsg">{this.state.validations.periodicity}</span>
									</div>
								</div>
								<div className="d-flex row">
								<div className="col-md-8">
									<label htmlFor="idchecknum" className="label-custom" >DD/Cheque No.</label> 
									<input tabIndex="13" value={this.state.formObj.checknum} id="idchecknum"  onChange={(e)=>{this.format("checknum",e)}}   onBlur={()=>{ this.validate("checknum")}} className="form-control form-control-sm" style={this.state.validations.checknum===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
									<span className="errormsg">{this.state.validations.checknum}</span>
								</div>
								<div className="col-md-4 ">
									<label htmlFor="idcheckdate" className="label-custom" >DD/Cheque Date.</label> <br/>
									<DateOnly 
										name="checkdate"
										selected={this.state.endDate}
										value={this.state.formObj.checkdate }  
										onChange={(e)=>{this.changeEvent(e)}}
										dateformat={this.state.dateformat}
										class_name={ this.state.validations.checkdate ==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
										onBlur={this.validate}
										tabindex={14}
									/> 
								 	<span className="errormsg">{this.state.validations.checkdate}</span>
								</div>
							    </div>
								<div className="d-flex row">
								<div className="col-md-12">
									<label htmlFor="idissueno" className="label-custom" >Issue No</label> 
									<input tabIndex="17" value={this.state.formObj.issueno} id="idissueno" onChange={(e)=>{this.format("issueno",e)}}   onBlur={()=>{ this.validate("issueno")}} className="form-control form-control-sm" style={this.state.validations.issueno===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
									<span className="errormsg">{this.state.validations.issueno}</span>
								</div>
							    </div>
							</div>

							<div className="p-2 flex-fill">
							<div className="d-flex row">
								<div className="col-md-6">
								    <label htmlFor="idjournaltype" className="label-custom" >Journal Type<span className="star">*</span></label> 
							 		<select tabIndex="3" value={this.state.formObj.journaltype} id="idjournaltype" onChange={(e)=>{this.format("journaltype",e)}} onBlur={()=>{this.validate("journaltype")}} className="form-control form-control-sm" style={this.state.validations.journaltype===""?{}:{"borderColor":"red"}}>
									<option value=""></option>
									{this.renderJournalTypes()}
										</select>
								    <span className="errormsg">{this.state.validations.journaltype}</span>
							   </div>
							   <div className="col-md-6">
									<label htmlFor="idjournalpublisher" className="label-custom" >Publisher Name<span className="star">*</span></label> 
									<input tabIndex="4" value={this.state.formObj.journalpublisher} id="idjournalpublisher"  onChange={(e)=>{this.format("journalpublisher",e)}}   onBlur={()=>{ this.validate("journalpublisher")}} className="form-control form-control-sm" style={this.state.validations.journalpublisher===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
									<span className="errormsg">{this.state.validations.journalpublisher}</span>
							   </div>
					        </div>
							<div className="d-flex row">
							<div className="col-md-6 ">
								<label htmlFor="idvendor" className="label-custom" >Vendor</label> 
								<input tabIndex="7" value={this.state.formObj.vendor} id="idvendor" onChange={(e)=>{this.format("vendor",e)}}   onBlur={()=>{ this.validate("vendor")}} className="form-control form-control-sm" style={this.state.validations.vendor===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
								<span className="errormsg">{this.state.validations.vendor}</span>
					       </div>
					        <div className="col-md-6 ">
								<label htmlFor="idponum" className="label-custom" >PO No</label> 
								<input tabIndex="8" value={this.state.formObj.ponum} id="idponum"  onChange={(e)=>{this.format("ponum",e)}}   onBlur={()=>{ this.validate("ponum")}} className="form-control form-control-sm" style={this.state.validations.ponum===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
								<span className="errormsg">{this.state.validations.ponum}</span>
							</div>
							</div>

							<div className="d-flex row">
							<div className="col-md-8">
								<label htmlFor="idvolumeno" className="label-custom" >Volume No</label> 
								<input tabIndex="11" value={this.state.formObj.volumeno} id="idvolumeno"  onChange={(e)=>{this.format("volumeno",e)}}   onBlur={()=>{ this.validate("volumeno")}} className="form-control form-control-sm" style={this.state.validations.volumeno===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
								<span className="errormsg">{this.state.validations.volumeno}</span>
							</div>
							<div className="col-md-4 ">
								<label htmlFor="idpodate" className="label-custom" >PO Date</label><br/>
								<DateOnly 
								    name="billdate"
								    selected={this.state.endDate}
								    value={this.state.formObj.podate }  
									onChange={(e)=>{this.changeEvents(e)}}
									dateformat={this.state.dateformat}
									class_name={ this.state.validations.podate ==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
									onBlur={this.validate}
									tabindex={12}
								/> 
								<span className="errormsg">{this.state.validations.podate}</span>
							</div>	
							</div>
							<div className="d-flex row">
							<div className="col-md-6">
								<label htmlFor="idmonth" className="label-custom" >Month</label> 
								<select tabIndex="15" value={this.state.formObj.month} id="idmonth"  onChange={(e)=>{this.format("month",e)}}  placeholder="Enter Journalmonth" onBlur={()=>{ this.validate("month")}} className="form-control form-control-sm" style={this.state.validations.month===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required > 
				
								<option value=""></option> 
								<option>Jan</option>
								<option>Feb</option>
								<option>Mar</option>
								<option>Apr</option>
								<option>May</option>
								<option>Jun</option>
								<option>Jul</option>
								<option>Aug</option>
								<option>Sep</option>
								<option>Oct</option>
								<option>Nov</option>
								<option>Dec</option>
								</select>
								<span className="errormsg">{this.state.validations.month}</span>
							
								</div>
								<div className="col-md-6">
									<label htmlFor="idyear" className="label-custom" >Year</label> 
									<select tabIndex="16" value={this.state.formObj.year} id="idyear"  onChange={(e)=>{this.format("year",e)}}  placeholder="Enter Journalyear" onBlur={()=>{ this.validate("year")}} className="form-control form-control-sm" style={this.state.validations.year===""?{}:{"borderColor":"red"}}  className='form-control form-control-sm' required >
										<option value=""></option>
										{this.state.year.map(i=>{
											return(
												<option key={i} value={i}>{i}</option>
											)
										})}
									</select> 
									<span className="errormsg">{this.state.validations.year}</span>
								</div>

							</div>

							<div className="d-flex row">
							<div className="col-md-12">
								<label htmlFor="idjournalstatus" className="label-custom" >Status<span className="star">*</span></label> 
								<select tabIndex="18"  value={this.state.formObj.journalstatus} id="idjournalstatus" onChange={(e)=>{this.format("journalstatus",e)}} onBlur={()=>{this.validate("journalstatus")}} className="form-control form-control-sm" style={this.state.validations.journalstatus===""?{}:{"borderColor":"red"}}>
									<option value=""></option>
									<option value="Available">Available</option>
									<option value="Not Available">Not Available</option>
									<option value="Damaged">Damaged</option>
									
								</select>
								<span className="errormsg">{this.state.validations.journalstatus}</span>
							    </div>
							</div>

							</div>

						</div>
				<div className="d-flex row">
					<div className="p-2 flex-fill">
						<label htmlFor="idnotes" className="label-custom" >Notes</label> 
						<textarea tabIndex="19"  value={this.state.formObj.notes} id="idnotes"  rows="1"  onChange={(e)=>{this.format("notes",e)}} className="form-control "   onBlur={()=>{ this.validate("notes")}}   style={this.state.validations.notes===""?{}:{"borderColor":"red"}} />  
						<span className="errormsg">{this.state.validations.notes}</span>
					</div>
				</div>
				<div className="d-flex row justify-content-end">
				
					<div className="p-2">
						<button type="button" disabled={this.state.disvalue} className={this.state.deletebtn} onClick={()=>{this.deleteObj()}}>Delete</button>
					</div>
					<div className="p-2">
						<button type="button"  className={this.state.classname} onClick={()=>{this.printbarcode()}}>Print Barcode</button>
					</div>
					
					<div className="p-2">
						<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.addObj()}}>New</button>
					</div>
					
					<div className="p-2">
						<button type="button" className="btn btn-success btn-sm"onClick={()=>{this.saveObj()}} >Save</button>
					</div>
					<div className="p-2">
						<button type="button" className="btn btn-warning btn-sm" onClick={()=>{this.goToSearch()}}>Cancel</button>
					</div>	
				</div>
				</div>:null}
				<br/>
				<Modal size="lg" show={this.state.showModal} onHide={()=>{this.setState({showModal:false,searchtable:false})}} >
					<Modal.Header closeButton>
						<Modal.Title>Book Catalog</Modal.Title>
					</Modal.Header>
					<ModalBody>
						<div className="d-flex row">
							<div className="p-2 flex-fill">
								<label htmlFor="idpissn" className="label-custom" >P-ISSN<span className="star">*</span></label> 
								<input value={this.state.formObj.pissn} id="idpissn"  onChange={(e)=>{this.format("pissn",e)}}  className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>	
							<div className="p-2 flex-fill">
								<label htmlFor="idjournaltitle" className="label-custom" >Title<span className="star">*</span></label> 
								<input value={this.state.formObj.journaltitle} id="idjournaltitle"  onChange={(e)=>{this.format("journaltitle",e)}}    className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>
							<div className="p-2">
								<label htmlFor="idjournalpublisher" className="label-custom" >Publisher Name<span className="star">*</span></label> 
								<input value={this.state.formObj.journalpublisher} id="idjournalpublisher"  onChange={(e)=>{this.format("journalpublisher",e)}}   className="form-control form-control-sm" type="text" className='form-control form-control-sm' required /> 
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>
								<div className="p-2">
								<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchType(this.state.formObj.pissn,this.state.formObj.journaltitle,this.state.formObj.journalpublisher)}}>Go</button>
							</div>
						</div>
						{this.state.searchtable?<div className="d-flex row">
							<div className="p-2 flex-fill">
								<table className="table table-sm">
								<thead>
									<tr>
										<td>P-ISSN</td>
										<td>Journal Title</td>
										<td>Journal Author</td>
									</tr>
								</thead>
								{this.renderObjlist()}
								</table>
							</div>
						</div>:null}
					</ModalBody>
				<ModalFooter>
					<Button variant="warning" onClick={()=>{this.setState({showModal:false,searchtable:false})}}>CLOSE</Button>
				</ModalFooter>
			</Modal> 
			<Modal size="md" centered show={this.state.show} onHide={()=>{this.setState({show:false})}}>
		<ModalBody>
		<div className="d-flex justify-content-right">
			<div className="p-2">Are you sure want to delete the record!!.</div>
		</div>
		<div className="d-flex justify-content-end">
		<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.conformDelete()}}>YES</button></div>
		<div className="p-2"><button className="btn btn-primary" onClick={()=>{this.setState({show:false})}}>N0</button></div>
			</div>
		</ModalBody>
	</Modal>
	<Modal  size="md" centered show={this.state.showMessage} onHide={()=>{this.setState({showMessage:false})}}>
	<ModalBody>
		<div className="d-flex justify-content-right">
			<div className="p-2">This Journal is not available Now!! you can not delete it</div>
		</div>
		<div className="d-flex justify-content-end">
		<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({showMessage:false})}}>OK</button></div>
		
			</div>
		</ModalBody>
	</Modal>
		
			
		
		</div>
	)
}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Journals_CatalogForm);
